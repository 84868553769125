@font-face {
  font-family: Inter;
  src: local(Inter-Light), url(./fonts/Inter-Light.woff2) format('woff2'),
    url(./fonts/Inter-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Inter;
  src: local(Inter-Regular), url(./fonts/Inter-Regular.woff2) format('woff2'),
    url(./fonts/Inter-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Inter;
  src: local(Inter-Medium), url(./fonts/Inter-Medium.woff2) format('woff2'),
    url(./fonts/Inter-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Inter;
  src: local(Inter-Bold), url(./fonts/Inter-Bold.woff2) format('woff2'),
    url(./fonts/Inter-Bold.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: Inter, 'Helvetica', sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  background-color: white;
  color: #000;
}

.container {
  padding: 20px 70px;
}

h1 {
  font-size: 40px;
}

h3 {
  font-size: 18px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

table table td,
table table th {
  border-right: 0;
}
table table tr:last-child td,
table table tr:last-child th {
  border-bottom: 0;
}

td,
th {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  padding: 7px 4px;
  width: 25%;
  text-align: left;
}

td:last-child,
th:last-child {
  border-right: 0;
}

.inner-table-container {
  padding: 0;
  vertical-align: top;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.truncate {
  width: 12ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.status {
  border-radius: 4px;
  padding: 5px;
  color: #ffffff;
}

.big {
  font-size: 1.2em;
  text-align: center;
}
